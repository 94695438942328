import * as types from "../../mutation-types"
import axios from "axios"
import jsonApii from "../../../helpers/json_apii"
import VuexHelpers from "../../../helpers/vuex_helpers"
import lodashReplacementHelper from "../../../helpers/lodash_replacement_helpers"
import store from "../../index"

let debounceSavers = []

// initial state
const state = {
  answers: [],
  answersFeedbacks: [],
}

// getters
const getters = {
  answers: state => state.answers,
  // TODO: to replace by answersByQuizzesAttemptSummaryID
  answersByQuestionsAndUserId: state => (questionIds, userId) => {
    if(!questionIds || !userId) return []
    return state.answers.filter(a =>
      questionIds.includes(a.question_id)
      && a.user_id === userId
    )
  },
  answerByQuestionAndUserId: state => (questionId, userId) => {
    return state.answers.find(a => {
      return questionId === a.question_id && a.user_id === userId
    })
  },
  answersByQuestionId: state => (questionId) => {
    return state.answers.filter(a => questionId === a.question_id)
  },
  answersByQuestionIds: state => (questionIds) => {
    return state.answers.filter(a => questionIds.includes(a.question_id))
  },

  editedAnswers: (state, getters) => ( quiz, userId) => {
    return getters.answersByQuestionsAndUserId(quiz.question_ids, userId)
      .filter(a => a.edited === true)
  },
  answersFeedbackByAnswerId: state => (answerId) => {
    return state.answersFeedbacks.find(a => a.answer_id === answerId)
  },
}

// actions
const actions = {
  fetchAnswers({ commit }, { quiz, userId, questionIds }) {
    const filters = {
      user_id: { eq: userId },
      question_id: { eq: questionIds },
    }

    return axios
      .get(quiz.links.answers, {
        params: { filter: filters },
        paramsSerializer: jsonApii.toFilters,
      })
      .then( response => {
        jsonApii.getData(response.data).forEach(answer => {
          commit(types.CREATE_OR_UPDATE_ANSWER2, answer)
        })
        commit(
          types.UPDATE_SIMILARITY_CHECK_SUBMISSIONS,
          jsonApii.getIncluded(response.data, "similarity_check_submission")
        )
        jsonApii.getIncluded(response.data, "answers_feedback").forEach(answerFeedback => {
          commit(
            types.CREATE_OR_UPDATE_ANSWER_FEEDBACK,
            {
              ...answerFeedback,
              is_saved: true,
            }
          )
        })
      })
  },
  fetchQuizzesAttemptAnswers({ commit }, { quizzesAttemptSummaryId, questionId }) {
    return axios
      .get(`api/v1/quizzes/attempts/${quizzesAttemptSummaryId}/answers`, { params: { question_id: questionId } })
      .then( response => {
        jsonApii.getData(response.data).forEach(answer => {
          commit(types.CREATE_OR_UPDATE_ANSWER2, answer)
        })
      })
  },
  fetchQuestionAnswers({ commit }, question) {
    axios
      .get(question.links.answers)
      .then( response => {
        jsonApii.getData(response.data).forEach(answer => {
          console.log({ answer: answer })
          commit(types.CREATE_OR_UPDATE_ANSWER2, answer)
        })
      })
  },
  createAnswer(context, { question, choices_ids, quizzesAttemptSummary, content, spreadsheetContent, picture, save }) {
    let answer = {
      question_id: question.id,
      choices_ids: choices_ids,
      user_id: quizzesAttemptSummary.user_id,
      content: content,
      spreadsheet_content: spreadsheetContent,
      picture: picture,
      synchronized: false,
      edited: !save,
      last_edit_at: new Date().toISOString(),
    }


    // Create a save debouncer for this question
    let debouncer = debounceSavers.find(ds => ds.question_id === question.id)

    if (!debouncer) {
      debouncer = {
        question_id: question.id,
        debounce: lodashReplacementHelper.debounce((args) => {
          store.dispatch("saveAnswer", args)
        }, 3000),
      }
      debounceSavers.push(debouncer)
    }

    context.commit(types.CREATE_OR_UPDATE_ANSWER2, answer)

    // DUPLICATE-#10
    if (["normal", "exam"].includes(quizzesAttemptSummary.quiz_type)) {
      context.dispatch(
        "saveAnswersToLocalStorage",
        { quizId: quizzesAttemptSummary.quiz_id, userId: quizzesAttemptSummary.user_id }
      )
    }

    if(!save) return debouncer.debounce({  answer, quizzesAttemptSummary })

    return context.dispatch("saveAnswer", { answer, quizzesAttemptSummary })

  },
  async uploadPicture({ commit, dispatch }, { blob, question, quizzesAttemptSummary }) {
    const payload = {
      attachments: [blob.signed_id],
    }

    // This is a parametric link. See JsonApi::Quizzes::AttemptSummarySerializer on the backend for more details
    const url = quizzesAttemptSummary.links.create_attachments.replace(":question_id", question.id)

    const response = await axios.post(url, payload)

    const answer = { ...jsonApii.getData(response.data), edited: false }
    commit(types.CREATE_OR_UPDATE_ANSWER2, answer)

    // Answer should be saved in local storage after saving in the backend to
    // Update the "edited" state.
    // DUPLICATE-#10
    if (["normal", "exam"].includes(quizzesAttemptSummary.quiz_type)) {
      await dispatch(
        "saveAnswersToLocalStorage",
        { quizId: quizzesAttemptSummary.quiz_id, userId: quizzesAttemptSummary.user_id }
      )
    }

    return answer
  },
  saveAnswer(context, { answer, quizzesAttemptSummary }) {
    context.commit(types.CREATE_OR_UPDATE_ANSWER2, answer)

    const payload = {
      answers: [{
        question_id: answer.question_id,
        choices_ids: answer.choices_ids,
        content: answer.content,
        spreadsheet_content: answer.spreadsheet_content,
        picture : answer.picture,
        last_edit_at: answer.last_edit_at,
      }],
    }

    axios
      .post(quizzesAttemptSummary.links.create_answers, payload)
      .then( response => {
        context.commit(
          types.CREATE_OR_UPDATE_ANSWER2,
          { ...jsonApii.getData(response.data), edited: false }
        )
        // Answer should be saved in local storage after saving in the backend to
        // Update the "edited" state.
        // DUPLICATE-#10
        if (["normal", "exam"].includes(quizzesAttemptSummary.quiz_type)) {
          context.dispatch(
            "saveAnswersToLocalStorage",
            { quizId: quizzesAttemptSummary.quiz_id, userId: quizzesAttemptSummary.user_id }
          )
        }
      })
  },

  // ////////////////////////////////////////////////////////////////////////////
  // Local storage saving
  // ////////////////////////////////////////////////////////////////////////////
  // TODO: move from quizID and userID to quizzesAttemptSummaryID
  saveAnswersToLocalStorage({ getters }, { quizId, userId }) {
    let instanceId  = getters.quizInstanceByQuizId(quizId, userId).id
    let questionIds = getters.quizInstanceQuestionsByQuizId(quizId, userId).map(q => q.id)
    let answers = getters.answersByQuestionsAndUserId(questionIds, userId)

    return localStorage.setItem(
      localStorageKey(quizId, instanceId),
      JSON.stringify(answers)
    )
  },

  getAnswersFromLocalStorage({ commit, getters }, instance) {
    const quizId = instance.quiz_id

    if (localStorage.getItem(localStorageKey(quizId, instance.id))){
      const answers = JSON.parse(
        localStorage.getItem(localStorageKey(quizId, instance.id))
      )

      answers.forEach(answer => {
        const existingAnswer = getters.answerByQuestionAndUserId(
          answer.question_id,
          answer.user_id
        )
        // don't override the latest answers from another device
        if(new Date(existingAnswer?.last_edit_at) >= new Date(answer.last_edit_at)) return

        commit(types.CREATE_OR_UPDATE_ANSWER2, answer)
      })
    }
  },

  flushAnswersFromLocalStorage(context, quizId) {
    if (localStorage.getItem("answers_quiz_" + quizId)){
      localStorage.removeItem("answers_quiz_" + quizId)
    }
  },

  retrySyncAnswers({ getters, commit }, { quiz, quizzesAttemptSummary }) {
    const notSyncAnswers = getters
      .answersByQuestionsAndUserId(quiz.question_ids, quizzesAttemptSummary.user_id)
      .filter(a => a.synchronized === false)

    if(notSyncAnswers.length === 0) return

    const answersPayload = notSyncAnswers.map(answer => {
      return {
        choices_ids: answer.choices_ids,
        question_id: answer.question_id,
        content: answer.content,
        spreadsheet_content: answer.spreadsheet_content,
        last_edit_at: answer.last_edit_at,
      }
    })

    const payload = {
      answers: answersPayload,
      quiz_id: quiz.id,
    }

    return axios
      .post(quizzesAttemptSummary.links.create_answers, payload)
      .then( response => {
        jsonApii.getData(response.data).forEach(answer => {
          commit(types.CREATE_OR_UPDATE_ANSWER2, answer)
        })
      })
  },

  reSyncAllAnswers({ getters, commit }, { quiz, user, quizzesAttemptSummary }) {

    const answers = getters
      .answersByQuestionsAndUserId(quiz.question_ids, user.id)

    const answersPayload = answers.map(answer => {
      return {
        choices_ids: answer.choices_ids,
        question_id: answer.question_id,
        content: answer.content,
        spreadsheet_content: answer.spreadsheet_content,
        last_edit_at: answer.last_edit_at,
      }
    })

    const payload = {
      answers: answersPayload,
      quiz_id: quiz.id,
    }

    return axios
      .post(quizzesAttemptSummary.links.create_answers, payload)
      .then( response => {
        jsonApii.getData(response.data).forEach(answer => {
          commit(types.CREATE_OR_UPDATE_ANSWER2, answer)
        })
      })
  },

  sendEditedAnswers({ getters, commit, dispatch }, { quiz, quizzesAttemptSummary }) {
    const editedAnswers = getters.editedAnswers(quiz, quizzesAttemptSummary.user_id)

    if(editedAnswers.length === 0) return

    const answersPayload = editedAnswers.map(answer => {
      return {
        choices_ids: answer.choices_ids,
        question_id: answer.question_id,
        content: answer.content,
        spreadsheet_content: answer.spreadsheet_content,
        last_edit_at: answer.last_edit_at,
      }
    })

    const payload = {
      answers: answersPayload,
      quiz_id: quiz.id,
    }

    return axios
      .post(quizzesAttemptSummary.links.create_answers, payload)
      .then( response => {
        jsonApii.getData(response.data).forEach(answer => {
          commit(types.CREATE_OR_UPDATE_ANSWER2, { ...answer, edited: false })
          if (["normal", "exam"].includes(quiz.quiz_type)) {
            dispatch(
              "saveAnswersToLocalStorage",
              { quizId: quiz.id, userId: quizzesAttemptSummary.user_id }
            )
          }
        })
      })
  },

  handleEventAnswer({ commit }, { answer, status }) {
    return axios
      .post(answer.links.handle_event, { status: status })
      .then( response => {
        commit(
          types.CREATE_OR_UPDATE_ANSWER2,
          jsonApii.getData(response.data)
        )
      })
  },

  updateAnswer({ commit }, answer) {
    return axios
      .patch(answer.links.self, answer)
      .then( response => {
        commit(
          types.CREATE_OR_UPDATE_ANSWER2,
          jsonApii.getData(response.data)
        )
      })
  },
  deletePictureFromAnswer(context , picture ) {
    axios
      .delete(picture.self)
      .then(() => {
      })
      .catch(e => {
        VuexHelpers.newAppNotification(
          context,
          { message: `${e}`, type: "error" }
        )
      })
  },
  createFeedbackForAnswer(context, { answer, feedbackContent }) {
    console.log("createFeedbackForAnswer", answer, feedbackContent)
    context.commit(
      types.CREATE_OR_UPDATE_ANSWER_FEEDBACK,
      {
        answer_id: answer.id,
        user_content: feedbackContent,
        is_saved: false,
      }
    )
  },

  saveFeedbackForAnswer(context, { answer }) {
    const feedbackContent = context.getters.answersFeedbackByAnswerId(answer.id).user_content

    const payload = {
      answers_feedback: {
        user_content: feedbackContent,
      },
    }

    return axios
      .post(answer.links.feedbacks, payload)
      .then( response => {
        context.commit(
          types.CREATE_OR_UPDATE_ANSWER_FEEDBACK,
          {
            ...jsonApii.getData(response.data),
            is_saved: true,
          }
        )
      })
  },

  approveFeedback(context, { feedback }) {
    return axios
      .post(feedback.links.approve)
      .then( response => {
        context.commit(
          types.CREATE_OR_UPDATE_ANSWER_FEEDBACK,
          {
            ...jsonApii.getData(response.data),
            is_saved: true,
          }
        )
      })
  },
  generateFeedbackForAnswer(context, { answer }) {
    return axios
      .post(answer.links.feedback_suggestion)
      .then( response => {
        context.commit(
          types.CREATE_OR_UPDATE_ANSWER_FEEDBACK,
          {
            ...jsonApii.getData(response.data),
            is_saved: true,
          }
        )

        return jsonApii.getIncluded(response.data, "llm_response_log")
      })
  },
}

// mutations
const mutations = {
  [types.UPDATE_ANSWERS_NEW](state, answers){
    answers.forEach(answer => {
      VuexHelpers.createOrUpdate(
        state.answers,
        answer,
        ["question_id", "user_id"]
      )
    })
  },

  [types.CREATE_OR_UPDATE_ANSWER2](state, answer){
    VuexHelpers.createOrUpdate(state.answers, answer, ["question_id", "user_id"])
  },

  [types.FLUSH_ANSWERS](state){
    state.answers = []
  },

  [types.CREATE_OR_UPDATE_ANSWER_FEEDBACK](state, answerFeedback){
    VuexHelpers.createOrUpdate(state.answersFeedbacks, answerFeedback, ["answer_id"])
  },
}

const localStorageKey = (quizId, instanceId) => {
  return `answers_quiz_${quizId}_${instanceId}`
}

export default {
  state,
  getters,
  actions,
  mutations,
}
