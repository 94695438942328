export let UPDATE_QUESTION_FILTER = "UPDATE_QUESTION_FILTER"
export let DELETE_QUIZZES_GROUP = "DELETE_QUIZZES_GROUP"
export let UPDATE_QUIZZES_GROUPS = "UPDATE_QUIZZES_GROUPS"
export let UPDATE_QUIZZES_STATS = "UPDATE_QUIZZES_STATS"
export let UPDATE_QUIZZES_STAT = "UPDATE_QUIZZES_STAT"
export let UPDATE_SIMILARITY_CHECK_SUBMISSION = "UPDATE_SIMILARITY_CHECK_SUBMISSION"
export let UPDATE_SIMILARITY_CHECK_SUBMISSIONS = "UPDATE_SIMILARITY_CHECK_SUBMISSIONS"

export const UPDATE_CURRENT_USER = "UPDATE_CURRENT_USER"
export const CREATE_OR_UPDATE_USER = "CREATE_OR_UPDATE_USER"
export const DESTROY_USER = "DESTROY_USER"

export const UPDATE_QUIZ_DRAFT = "UPDATE_QUIZ_DRAFT"

export const UPDATE_QUIZ_DRAFT_STATE = "UPDATE_QUIZ_DRAFT_STATE"
export const QUIZZES_LOADING = "QUIZZES_LOADING"
export const CURRENT_CORRECTION_LOADING = "CURRENT_CORRECTION_LOADING"

export const UPDATE_QUIZZES = "UPDATE_QUIZZES"
export const UPDATE_QUIZZES_COUNT = "UPDATE_QUIZZES_COUNT"
export const UPDATE_QUIZZES_OWNERS = "UPDATE_QUIZZES_OWNERS"
export const DELETE_QUIZ = "DELETE_QUIZ"
export const CREATE_OR_UPDATE_QUIZZ = "CREATE_OR_UPDATE_QUIZZ"
export const FLUSH_QUIZZES = "FLUSH_QUIZZES"

export const UPDATE_ANSWERS_NEW = "UPDATE_ANSWERS_NEW"
export const CREATE_OR_UPDATE_ANSWER2 = "CREATE_OR_UPDATE_ANSWER2"
export const CREATE_OR_UPDATE_ANSWER_FEEDBACK = "CREATE_OR_UPDATE_ANSWER_FEEDBACK"

export const CREATE_ANSWER = "CREATE_ANSWER"
export const CREATE_ANSWERS = "CREATE_ANSWERS"
export const CREATE_OR_UPDATE_ANSWER = "CREATE_OR_UPDATE_ANSWER"
export const FLUSH_ANSWERS = "FLUSH_ANSWERS"


export const UPDATE_ANSWER_COUNT = "UPDATE_ANSWER_COUNT"

export const UPDATE_SCORES = "UPDATE_SCORES"
export const UPDATE_SCORES_COUNT = "UPDATE_SCORES_COUNT"
export const CREATE_OR_UPDATE_SCORE = "CREATE_OR_UPDATE_SCORE"
export const CREATE_OR_UPDATE_SCORES = "CREATE_OR_UPDATE_SCORES"
export const SCORES_LOADING = "SCORES_LOADING"
export const DELETE_SCORE = "DELETE_SCORE"

export const UPDATE_USERS = "UPDATE_USERS"
export const DESTROY_USERS = "DESTROY_USERS"
export const UPDATE_USERS_COUNT = "UPDATE_USERS_COUNT"

export const UPDATE_GROUPS = "UPDATE_GROUPS"
export const UPDATE_GROUPS_COUNT = "UPDATE_GROUPS_COUNT"
export const UPDATE_GROUP = "UPDATE_GROUP"
export const DELETE_GROUP = "DELETE_GROUP"
export const CREATE_GROUP = "CREATE_GROUP"
export const UPDATE_GROUP_MEMBERS = "UPDATE_GROUP_MEMBERS"
export const DELETE_GROUP_MEMBERS = "DELETE_GROUP_MEMBERS"

export const UPDATE_QUESTIONS_STATS = "UPDATE_QUESTIONS_STATS"
export const UPDATE_QUESTION_STATS = "UPDATE_QUESTION_STATS"
export const UPDATE_QUESTIONS_STATS_LOADING = "UPDATE_QUESTIONS_STATS_LOADING"

export const UPDATE_SCAN_SHEETS = "UPDATE_SCAN_SHEETS"
export const UPDATE_SCAN_SHEET = "UPDATE_SCAN_SHEET"
export const DELETE_SCAN_SHEET = "DELETE_SCAN_SHEET"
export const SCAN_SHEET_LOADING = "SCAN_SHEET_LOADING"

export const UPDATE_SCAN_PAPERS = "UPDATE_SCAN_PAPERS"
export const UPDATE_SCAN_PAPER = "UPDATE_SCAN_PAPER"
export const SCAN_PAPER_LOADING = "SCAN_PAPER_LOADING"

export const CREATE_OR_UPDATE_QUIZ_INSTANCE = "CREATE_OR_UPDATE_QUIZ_INSTANCE"
export const UPDATE_QUIZ_INSTANCES = "UPDATE_QUIZ_INSTANCES"
export const DELETE_QUIZ_INSTANCE = "DELETE_QUIZ_INSTANCE"
export const QUIZ_INSTANCE_LOADING = "QUIZ_INSTANCE_LOADING"
export const QUIZ_INSTANCE_TIME_OFFSET_UPDATE = "QUIZ_INSTANCE_TIME_OFFSET_UPDATE"

export const CREATE_OR_UPDATE_QUIZ_INSTANCE_COUNTDOWN = "CREATE_OR_UPDATE_QUIZ_INSTANCE_COUNTDOWN"

export const CREATE_OR_UPDATE_QUESTION = "CREATE_OR_UPDATE_QUESTION"
export const CREATE_OR_UPDATE_QUESTIONS = "CREATE_OR_UPDATE_QUESTIONS"
export const UPDATE_QUESTIONS_LOADING = "UPDATE_QUESTIONS_LOADING"
export const DELETE_QUESTION = "DELETE_QUESTION"
export const FLUSH_QUESTIONS = "FLUSH_QUESTIONS"

export const CREATE_APP_NOTIFICATION = "CREATE_APP_NOTIFICATION"

export const UPDATE_QUIZZES_TAGS = "UPDATE_QUIZZES_TAGS"
export const CREATE_OR_UPDATE_TAG = "CREATE_OR_UPDATE_TAG"
export const DELETE_TAG = "DELETE_TAG"
export const DELETE_TAGS = "DELETE_TAGS"

export const UPDATE_TAGS_SET = "UPDATE_TAGS_SET"
export const CREATE_OR_UPDATE_TAGS_SET = "CREATE_OR_UPDATE_TAGS_SET"

export const UPDATE_QUESTIONS_TAGS = "UPDATE_QUESTIONS_TAGS"
export const CREATE_OR_UPDATE_QUESTION_TAG = "CREATE_OR_UPDATE_QUESTION_TAG"
export const DELETE_QUESTION_TAG = "DELETE_QUESTION_TAG"

export const UPDATE_QUESTIONS_TAGS_CATEGORY = "UPDATE_QUESTIONS_TAGS_CATEGORY"
export const CREATE_OR_UPDATE_QUESTION_TAGS_CATEGORY = "CREATE_OR_UPDATE_QUESTION_TAGS_CATEGORY"
export const DELETE_QUESTION_TAGS_CATEGORY = "DELETE_QUESTION_TAGS_CATEGORY"

export const CREATE_OR_UPDATE_PROCTORING_IMAGES = "CREATE_OR_UPDATE_PROCTORING_IMAGES"
export const CREATE_OR_UPDATE_PROCTORING_IMAGE = "CREATE_OR_UPDATE_PROCTORING_IMAGE"
export const PROCTORING_IMAGES_LOADING = "PROCTORING_IMAGES_LOADING"

export const CREATE_OR_UPDATE_PROCTORING_IMAGE_ANALYSIS = "CREATE_OR_UPDATE_PROCTORING_IMAGE_ANALYSIS"
export const PROCTORING_IMAGES_ANALYSIS_LOADING = "PROCTORING_IMAGES_ANALYSIS_LOADING"

export const CREATE_OR_UPDATE_STUDENT_EVENT = "CREATE_OR_UPDATE_STUDENT_EVENT"
export const CREATE_OR_UPDATE_STUDENT_EVENTS = "CREATE_OR_UPDATE_STUDENT_EVENTS"

export const UPDATE_MOODLE_COURSES = "UPDATE_MOODLE_COURSES"
export const UPDATE_MOODLE_CATEGORIES = "UPDATE_MOODLE_CATEGORIES"

export const UPDATE_EMAIL = "UPDATE_EMAIL"

export const UPDATE_SCHOOL = "UPDATE_SCHOOL"
export const CREATE_OR_UPDATE_PROCTORING_IMAGE_ANALYSIS_STATS = "CREATE_OR_UPDATE_PROCTORING_IMAGE_ANALYSIS_STATS"

export const UPDATE_PROCTORING_STATE = "UPDATE_PROCTORING_STATE"
export const UPDATE_SHOW_CAMERA_STATE = "UPDATE_SHOW_CAMERA_STATE"

export const UPDATE_QUIZZES_DOCUMENT = "UPDATE_QUIZZES_DOCUMENT"
export const UPDATE_QUIZZES_DOCUMENTS = "UPDATE_QUIZZES_DOCUMENTS"
export const DELETE_QUIZZES_DOCUMENT = "DELETE_QUIZZES_DOCUMENT"

export const UPDATE_PAPER_SHEET = "UPDATE_PAPER_SHEET"

export const UPDATE_CHAT_CHANNELS = "UPDATE_CHAT_CHANNELS"
export const UPDATE_CHAT_CHANNEL = "UPDATE_CHAT_CHANNEL"

export const UPDATE_CHAT_MESSAGES = "UPDATE_CHAT_MESSAGES"
export const UPDATE_CHAT_MESSAGE = "UPDATE_CHAT_MESSAGE"

export const UPDATE_TASK_PROGRESS_MANAGERS = "UPDATE_TASK_PROGRESS_MANAGERS"
export const UPDATE_TASK_PROGRESS_MANAGER = "UPDATE_TASK_PROGRESS_MANAGER"

export const UPDATE_QUIZZES_SUBSCRIPTIONS = "UPDATE_QUIZZES_SUBSCRIPTIONS"
export const DELETE_QUIZZES_SUBSCRIPTION = "DELETE_QUIZZES_SUBSCRIPTION"

export const UPDATE_SCHOOL_USERS = "UPDATE_SCHOOL_USERS"
export const UPDATE_SCHOOL_USER = "UPDATE_SCHOOL_USER"
export const UPDATE_SCHOOL_USERS_COUNT = "UPDATE_SCHOOL_USERS_COUNT"

export const CREATE_OR_UPDATE_TIME_ACCOMMODATION = "CREATE_OR_UPDATE_TIME_ACCOMMODATION"
export const CREATE_OR_UPDATE_TIME_ACCOMMODATION_SUMMARY = "CREATE_OR_UPDATE_TIME_ACCOMMODATION_SUMMARY"
export const CREATE_OR_UPDATE_ACCESSIBILITY_OPTION = "CREATE_OR_UPDATE_ACCESSIBILITY_OPTION"
export const DELETE_ACCESSIBILITY_OPTION = "DELETE_ACCESSIBILITY_OPTION"

export const CREATE_OR_UPDATE_QUIZ_SESSION = "CREATE_OR_UPDATE_QUIZ_SESSION"
export const CREATE_OR_UPDATE_QUIZ_SESSIONS = "CREATE_OR_UPDATE_QUIZ_SESSIONS"
export const DELETE_QUIZ_SESSION = "DELETE_QUIZ_SESSION"

export const CREATE_OR_UPDATE_PUBLIC_QUIZ_SESSION = "CREATE_OR_UPDATE_PUBLIC_QUIZ_SESSION"

export const CREATE_OR_UPDATE_QUIZZES_ATTEMPT_SUMMARIES = "CREATE_OR_UPDATE_QUIZZES_ATTEMPT_SUMMARIES"
export const CREATE_OR_UPDATE_QUIZZES_ATTEMPT_SUMMARY = "CREATE_OR_UPDATE_QUIZZES_ATTEMPT_SUMMARY"

export const UPDATE_PAPER_QUIZ = "UPDATE_PAPER_QUIZ"

export const CREATE_OR_UPDATE_STUDENT_SCREENSHOT = "CREATE_OR_UPDATE_STUDENT_SCREENSHOT"
export const CREATE_OR_UPDATE_STUDENT_SCREENSHOTS = "CREATE_OR_UPDATE_STUDENT_SCREENSHOTS"
export const STUDENT_SCREENSHOTS_LOADING = "STUDENT_SCREENSHOTS_LOADING"
