import * as types from "../../mutation-types"
import VuexHelpers from "../../../helpers/vuex_helpers"
import axios from "axios"
import jsonApii from "../../../helpers/json_apii"

// initial state
const state = {
  studentScreenshots: [],
}

// getters
const getters = {
  studentScreenshots: state => (scoreId) => {
    return state.studentScreenshots.filter(s => s.score_id === scoreId)
  },
}

// actions
const actions = {
  fetchScreenshotsByScore({ commit }, { score, timeWindow = [], maxVisibleScreenshots = 50 }) {
    const params = {
      max_samples: maxVisibleScreenshots,
    }
    if (Array.isArray(timeWindow) && timeWindow.length === 2) {
      params.start_time = new Date(timeWindow[0]).toISOString()
      params.end_time = new Date(timeWindow[1]).toISOString()
    }
    return axios.get(score.links.student_screenshots, { params })
      .then(response => {
        const screenshots = jsonApii.getData(response.data)
        commit(types.CREATE_OR_UPDATE_STUDENT_SCREENSHOTS, screenshots)
        return screenshots
      })
  },
}

// mutations
const mutations = {
  [types.CREATE_OR_UPDATE_STUDENT_SCREENSHOT]({ studentScreenshots }, studentScreenshot) {
    VuexHelpers.createOrUpdate(studentScreenshots, studentScreenshot)
  },
  [types.CREATE_OR_UPDATE_STUDENT_SCREENSHOTS]({ studentScreenshots }, newStudentScreenshots) {
    newStudentScreenshots.forEach(
      studentScreenshot => VuexHelpers.createOrUpdate(studentScreenshots, studentScreenshot)
    )
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}
