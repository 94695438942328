/**
 * Extract image URLs from markdown-style content
 * @param {string} content - The markdown content to parse
 * @returns {string[]} - Array of image URLs
 */
export const extractImageUrls = (content) => {
  if (!content) return []
  const imagePattern = /!\[.*?\]\((.*?)\)/g
  const matches = [...content.matchAll(imagePattern)]
  return matches.map(match => match[1])
}
