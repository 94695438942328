import * as Sentry from "@sentry/vue"
import Vue from "vue"

const sentryDsn = window.SENTRY_DSN_FRONT
const sentryEnv = window.SENTRY_ENV

export default {
  initSentry: (router = null) => {
    Sentry.init({
      Vue,
      dsn: sentryDsn,
      environment: sentryEnv,
      integrations: [
        Sentry.browserTracingIntegration(
          router ? { router } : {
            tracingOrigins: ["localhost", /^https:\/\/app\.evalmee\.com\/api/],
          }
        ),
        Sentry.replayIntegration({
          networkDetailAllowUrls: [window?.location?.origin],
        }),
        Sentry.vueIntegration({
          tracingOptions: {
            trackComponents: true,
            hooks: ["mount", "update", "destroy"],
          },
        }),
      ],
      // Performance Monitoring
      tracesSampleRate: 0.01, // Capture 100% of the transactions, reduce in production!
      // Session Replay
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1.0,
    })
  },

  sentrySetUser: ({ user, currentSchool, schools }) => {
    Sentry.setUser({
      id: user.id,
      username: user.name,
      email: user.email,
      teacher: user.teacher,
      current_school: currentSchool?.name,
      schools: schools.map(school => school.name).join(", "),
    })
  },

}
