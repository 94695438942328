import * as types from "../../mutation-types"
import VuexHelpers from "../../../helpers/vuex_helpers"
import axios from "axios"
import jsonApii from "@/helpers/json_apii"

// initial state
const state = {
  quizzesGroups : [],
}

// getters
const getters = {
  quizzesGroups: state => (quizId) => state.quizzesGroups.filter(s => s.quiz_id === quizId),
  quizzesGroupById: state => (groupsId) => state.quizzesGroups.find(s => s.id === groupsId  ),
}

// actions
const actions = {
  async createQuizzesGroup({ commit }, { quiz, ...params }) {
    const response = await axios.post(
      quiz.links.groups,
      { group: params }
    )
    commit(types.UPDATE_QUIZZES_GROUPS, [jsonApii.getData(response.data)])
    return jsonApii.getData(response.data)
  },
  async destroyQuizzesGroupsSubscriptions({ commit }, { group, subscriptionIds }) {
    const response = await axios.post(
      group.links.destroy_batch_subscription_groups,
      { subscription_ids:subscriptionIds }
    )

    const subscriptions = jsonApii.getData(response.data)
    commit(types.UPDATE_QUIZZES_SUBSCRIPTIONS, subscriptions)
    return subscriptions
  },
  async createQuizzesGroupsSubscriptions({ commit }, { group, subscriptionIds }) {
    const response = await axios.post(
      group.links.create_batch_subscription_groups,
      { subscription_ids:subscriptionIds }
    )

    const subscriptions = jsonApii.getData(response.data)
    commit(types.UPDATE_QUIZZES_SUBSCRIPTIONS, subscriptions)
    return subscriptions
  },
}

// mutations
const mutations = {
  [types.UPDATE_QUIZZES_GROUPS](state, groups) {
    groups.forEach(
      group => VuexHelpers.createOrUpdate(state.quizzesGroups, group)
    )
  },
  [types.DELETE_QUIZZES_GROUP](state, groupId) {
    state.quizzesGroups = state.quizzesGroups.filter(s => s.id !== groupId)
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}
