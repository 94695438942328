import * as types from "../../mutation-types"
import axios from "axios"
import jsonApii from "../../../helpers/json_apii"
import arrayHelpers from "../../../helpers/array_helpers"
import VuexHelpers from "../../../helpers/vuex_helpers"
import instanceModels from "./instances/instance_nodes_models"
import instanceModel from "./instances/instance_model"
import { Answer } from "@/models/answerModel"

// initial state
const state = {
  instances: [],
  instancesLoading: false,
  timeOffset: 0,
  questionFilter: "all",
}

// getters
const getters = {
  questionFilter: state => { return state.questionFilter },
  instancesLoading: state => { return state.instancesLoading },
  timeOffset: state => { return state.timeOffset },
  quizInstancesByQuizId: state => (quizId) => {
    return state.instances.filter(a => a.quiz_id === quizId)
  },
  quizInstanceByQuizId: state => (quizId, userId) => {
    return state.instances.find(a => a.quiz_id === quizId && a.user_id === userId)
  },
  quizInstanceByQuizId2: state => (quizId, userId) => {
    const instance = state.instances.find(a => a.quiz_id === quizId && a.user_id === userId)
    if (!instance) return null
    
    return new instanceModel.QuizInstance(instance)
  },
  quizInstanceContentByQuizId: state => (quizId, userId) => {
    return instanceModels.parseInstance(
      state.instances.find(a => a.quiz_id === quizId && a.user_id === userId)
    )
  },
  quizInstanceQuestionsByQuizId: (state, getters) => (quizId, userId) => {
    return getters.quizInstanceByQuizId(quizId, userId)
      ?.quiz_data
      ?.attributes
      ?.sections
      ?.flatMap( s => (
        s.attributes.exercises.flatMap(e => (
          e.attributes.questions
        ))
      )) || []
  },

  // This should be transferred to instance_nodes_models.js
  answeredQuestionsInInstances: (state, getters) => (quizId, userId) => {
    return getters.quizInstanceQuestionsByQuizId(quizId, userId).filter(q => {
      const answer = getters.answerByQuestionAndUserId(q.attributes.id, userId)
      return answer && new Answer(answer).isAnswered
    })
  },

  nextUnansweredExerciseByQuizId: (state, getters) => (quizId, userId,afterExerciceId = null) => {
    return getters.quizInstanceContentByQuizId(quizId, userId).firstUnansweredExercise
  },

  //todo remove this function
  nextUnansweredQuestionByQuizId: (state, getters) => (quizId, userId, afterQuestion = null) => {
    let questions = getters.quizInstanceQuestionsByQuizId(quizId, userId)

    let questionIds = questions.map(q => q.id)
    let quizAnswers = getters
      .answersByQuestionsAndUserId(questionIds, userId) || []


    let afterQuestionIndex = questionIds.indexOf(afterQuestion?.id)
    let questionIdsAfterQuestion = questionIds.slice(afterQuestionIndex)
    let scopedQuestionIds = afterQuestion?.id ? questionIdsAfterQuestion : questionIds

    let questionWithAnswersIds = quizAnswers.map(a => a.question_id)
    let questionWithoutAnswersIds = arrayHelpers.difference(scopedQuestionIds, questionWithAnswersIds)
    let nextUnansweredQuestionId = questionWithoutAnswersIds[0]

    if (afterQuestion && !nextUnansweredQuestionId) {
      return getters.nextUnansweredQuestionByQuizId(quizId, userId)
    } else {
      return questions.find(q => q.id === nextUnansweredQuestionId)
    }
  },
  isQuizFullyAnsweredById: (state, getters) => (quizId, userId) => {
    return getters.nextUnansweredExerciseByQuizId(quizId, userId) == null &&
             getters.quizInstanceContentByQuizId(quizId, userId).type != null
  },
}

// actions
const actions = {
  fetchMyInstanceByQuiz({ commit, dispatch }, { quiz, quizzesAttemptSummary }) {
    commit(types.QUIZ_INSTANCE_LOADING, true)

    return axios
      .get(
        quiz.links.instance,
        {
          params: { score_id: quizzesAttemptSummary?.id },
        })
      .then( response => {
        const instance = jsonApii.getData(response.data)

        commit(types.CREATE_OR_UPDATE_QUIZ_INSTANCE, instance)
        commit(types.QUIZ_INSTANCE_TIME_OFFSET_UPDATE, instance?.date_time_utc_ms)


        commit(
          types.UPDATE_ANSWERS_NEW,
          jsonApii.getIncluded(response.data,"answer"),
          { root: true }
        )
        dispatch("getAnswersFromLocalStorage", instance)

        commit(types.QUIZ_INSTANCE_LOADING, false)

        jsonApii.getIncluded(response.data, "quizzes_document").forEach( document => {
          commit(types.UPDATE_QUIZZES_DOCUMENT, document, { root: true })
        })
        jsonApii.getIncluded(response.data, "questions_tag").forEach( question_tag => {
          commit(types.CREATE_OR_UPDATE_QUESTION_TAG, question_tag, { root: true })
        })
        jsonApii.getIncluded(response.data, "tags_category").forEach( tag_category => {
          commit(types.CREATE_OR_UPDATE_QUESTION_TAGS_CATEGORY, tag_category, { root: true })
        })
      })
      .catch()
  },

  fetchInstancesByQuiz({ commit, dispatch }, { quiz, userIds }) {
    commit(types.QUIZ_INSTANCE_LOADING, true)

    return axios
      .get(
        quiz.links.instances,
        {
          params: {
            filter: { user_id: { eq: userIds } },
          },
          paramsSerializer: jsonApii.toFilters,
        },
      )
      .then( response => {

        jsonApii.getData(response.data).forEach( instance =>
          commit(types.CREATE_OR_UPDATE_QUIZ_INSTANCE, instance)
        )

        commit(
          types.UPDATE_ANSWERS_NEW,
          jsonApii.getIncluded(response.data,"answer"),
          { root: true }
        )
        commit(types.QUIZ_INSTANCE_LOADING, false)

        jsonApii.getIncluded(response.data, "quizzes_document")
          .forEach( document => {
            commit(types.UPDATE_QUIZZES_DOCUMENT, document, { root: true })
          })
        jsonApii.getIncluded(response.data, "questions_tag").forEach( question_tag => {
          commit(types.CREATE_OR_UPDATE_QUESTION_TAG, question_tag, { root: true })
        })
        jsonApii.getIncluded(response.data, "tags_category").forEach( tag_category => {
          commit(types.CREATE_OR_UPDATE_QUESTION_TAGS_CATEGORY, tag_category, { root: true })
        })
      })
      .catch()
  },

  updateQuestionFilter({ commit }, questionFilter) {
    commit(types.UPDATE_QUESTION_FILTER, questionFilter)
  },
}

// mutations
const mutations = {
  [types.CREATE_OR_UPDATE_QUIZ_INSTANCE](state, instance){
    VuexHelpers.createOrUpdate(state.instances, instance)
  },
  [types.UPDATE_QUIZ_INSTANCES](state, instances){
    instances.forEach(instance => {
      VuexHelpers.createOrUpdate(state.instances, instance)
    })
  },

  [types.DELETE_QUIZ_INSTANCE](state, instance){
    const index = state.instances.findIndex(e => e.id === instance.id)
    state.instances.splice(index, 1)
  },

  [types.QUIZ_INSTANCE_LOADING](state, status){
    state.instancesLoading = status
  },

  [types.QUIZ_INSTANCE_TIME_OFFSET_UPDATE](state, serverTime){
    state.timeOffset = serverTime - Date.now()
  },
  [types.UPDATE_QUESTION_FILTER](state, questionFilter){
    state.questionFilter = questionFilter
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}
