import * as types from "../../mutation-types"
import VuexHelpers from "../../../helpers/vuex_helpers"
import axios from "axios"
import jsonApii from "../../../helpers/json_apii"

// initial state
const state = {
  studentEvents: [],
}

// getters
const getters = {
  studentEvents: state => (scoreId) => {
    return state.studentEvents.filter(e => e.score_id === scoreId)
  },
}

// actions
const actions = {
  async fetchStudentEventsByScore({ commit }, score) {
    try {
      const response = await axios.get(score.links.student_events)
      const events = jsonApii.getData(response.data)
      commit(types.CREATE_OR_UPDATE_STUDENT_EVENTS, events)
      return events
    } catch (error) {
      console.error("Error fetching student events:", error)
      throw error
    }
  },
}

// mutations
const mutations = {
  [types.CREATE_OR_UPDATE_STUDENT_EVENT]({ studentEvents }, studentEvent){
    VuexHelpers.createOrUpdate(studentEvents, studentEvent)
  },
  [types.CREATE_OR_UPDATE_STUDENT_EVENTS]({ studentEvents }, newStudentEvents){
    newStudentEvents.forEach(
      studentEvent => VuexHelpers.createOrUpdate(studentEvents, studentEvent)
    )
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}
