import { QuestionBehaviorFor } from "../../../../behaviors/quiz/question/question_behavior_for"
import { QuizNode } from "../../../../behaviors/quiz/quiz_node"
import vueI18n from "../../../../plugins/i18n"
import i18n from "../../../../plugins/i18n"
import { v1 as uuidv1 } from "uuid"
import store from "@/store"

class DraftNode extends QuizNode {
  constructor(attributes = {}, children = [], draftType = "online", indexIterator = null) {
    super()
    this.type = this.nodeType()
    this.indexIterator = indexIterator
    this.attributes = Object.assign({}, this.defaultAttributes(), attributes)
    // We don't use default parameter for children because defaultChildren
    // depends on attributes
    this.children = children?.length === 0 ? this.defaultChildren() : children
    // this.valid = this.hasValidChildren()
    this.draftType = draftType
    this.uuid = attributes?.uuid
    this.tempErrors = []
  }

  get isPaper() {return this.draftType === "paper"}

  get isOnline() {return this.draftType === "online"}

  get valid() { return this.isValid() }

  get draggableChildren() { return true }

  get editorBlockMinHeight() { return 30 }
  get editorBlockContentMinHeight() {
    return this.editorBlockMinHeight +
      this.children.reduce((sum, child) => sum + child.editorBlockContentMinHeight, 0)
  }

  get group() {
    return {
      name: this.nodeType(),
      put: false,
      pull: false,
    }
  }

  get hasDocument() {
    return this.attributes.document_uuids &&
           this.attributes.document_uuids.length > 0
  }

  /**
   * @return {undefined}
   */
  get placeholder() { return undefined }

  /**
   * @return {array}
   */
  get drownExercisePoolsCountByUuid() {
    return this.children
      .flatMap(child => child.drownExercisePoolsCountByUuid)
      .filter(Boolean)
  }

  get errors() {
    if(this.autoValidate) { return this.updateErrors() }
    return this.tempErrors
  }

  /**
   * Set this value to true to trigger auto validation for a node
   * If false, you will have to call validate() manually
   * @return {Boolean}
   */
  get autoValidate() { return true }

  updateErrors() {
    return this.tempErrors = this.validations().flatMap(validation => {
      if (validation.condition) return validation.label
      return []
    })
  }

  validate() {
    this.validateChildren()
    return this.updateErrors()
  }

  validateChildren() {
    this.children?.forEach(child => child.validate())
  }

  nodeType() { return null }

  defaultAttributes() { return { uuid: uuidv1() } }

  defaultChildren() { return [] }

  validChildrenTypes() { return [] }

  hasValidChildren() {
    // console.log(this.children.map(child => child.hasValidChildren()))
    return this.children.every(child => child?.isValid() && child?.hasValidChildren() ) &&
           this.isValid()
  }

  isValid() {
    const childrenTypes = this.validChildrenTypes()
    return this.children.every(child => childrenTypes.includes(child.nodeType())) &&
      // this.children.every(child => child.isValid()) &&
      this.errors.length === 0
  }

  validations() { return []}
}

class SectionDraft extends DraftNode {

  get draggableChildren() { return !this.attributes.root }

  get group() {
    return {
      name: "sectionChildren",
      put: this.attributes.root ? [] : ["sectionChildren"],
      pull: true,
    }
  }

  get editorBlockMinHeight() { return 100 }

  nodeType() { return "section" }

  validChildrenTypes() {
    if (this.attributes.root) return ["section"]
    return ["exercise", "random_exercise"]
  }

  defaultAttributes() {
    return {
      uuid: uuidv1(),
      document_uuids: [],
    }
  }

  get hasExplicitChildrenSection() {
    return this.children.filter(c=> c.type === "section").length > 1
  }

  get hasRandomExercises() {
    if (this.attributes.root) return this.children.some(child => child.hasRandomExercises)
    return this.children.some(child => child.type === "random_exercise")
  }

  get hasExplicitExercises() {
    if (this.attributes.root) {
      return this.children.some(child => child.hasExplicitExercises)
    }

    return this
      .children
      .filter(child => child.type === "exercise")
      .some(child => !child.isImplicit)
  }

  validations() {
    return [
      {
        label: "Ajoutez au moins une question ou exercice",

        condition: this.children.length < 1,
      },
      {
        label: "Un examen papier ne peut pas avoir plus de 120 questions",
        condition: this.hasTooManyQuestions,
      },
      {
        label: "Un examen papier ne peut pas avoir plus de 90 questions si une question à plus de 5 réponses",
        condition: this.hasTooManyQuestionsWithMoreThan5Choices,
      },
    ]
  }

  get hasTooManyQuestionsWithMoreThan5Choices() {
    if (!this.isPaper) return false
    if(!this.attributes.root) return false
    if(this.questionsCount() > 120) return true

    return this.maxChoicesCount > 5 && this.questionsCount() > 90
  }

  get hasTooManyQuestions() {
    if (!this.isPaper) return false
    if(!this.attributes.root) return false
    return this.questionsCount() > 120
  }

  questionsCount() {
    return this.children.reduce((sum, child) => {
      // console.log({ sum, child })
      return sum + child.questionsCount()
    }, 0)
  }

  get maxChoicesCount() {
    return Math.max(...this.children.map(child => child.maxChoicesCount))
  }

}

class ExerciseDraft extends DraftNode {


  get isImplicit() {
    if (this.children.length > 1) return false
    return this.attributes.name === null
  }

  get draggableChildren() { return !this.isImplicit }

  get group() {
    return {
      name: "exerciseChildren",
      put: ["exerciseChildren"],
      pull: true,
    }
  }

  get displayName() {
    if (this.isImplicit) return this.children[0].attributes.name
    return this.attributes.name
  }

  get drownExercisePoolsCountByUuid() { return [] }

  get maxChoicesCount() {
    return Math.max(...this.children.map(child => child.choicesCount))
  }

  get editorBlockMinHeight() { return 40 }

  nodeType() { return "exercise" }

  validChildrenTypes() { return ["question"] }

  defaultAttributes() {
    return {
      uuid: uuidv1(),
      document_uuids: [],
    }
  }

  /**
   * @return {Number}
   */
  coefficientsSum() {
    return this.children.reduce((sum, child) => {
      if(!child.isAnswerable) return sum
      return sum + child.attributes.coefficient
    }, 0)
  }

  /**
   * @return {Number}
   */
  questionsCount() {
    return this.children.reduce((sum, child) => {
      if(!child.isAnswerable) return sum
      return sum + 1
    }, 0)
  }

  /**
   * @return {Array}
   */
  validations() {
    return [
      {
        label: i18n.t("evalmee.home.teacher.quizEditor.exercise.errors.answerableContent"),
        condition: this.children.length > 0 &&
                   this.children.every(child => !child.isAnswerable),
      },
    ]
  }
}

class RandomExerciseDraft extends DraftNode {


  get isImplicit() {
    if (this.children.length > 1) return false
    return this.attributes.name === null
  }

  get draggableChildren() { return !this.isImplicit }

  get group() {
    return {
      name: "exerciseChildren",
      put: ["exerciseChildren"],
      pull: true,
    }
  }

  get hasAnExercisePool() {
    return !!this.attributes.exercise_pool_uuid
  }

  get isRandomQuestion() {
    return this.attributes.isRandomQuestion
  }

  get autoValidate() { return false }

  get exercisePool() {
    return store.getters.exercisesPoolsDraftByUuid(this.attributes.exercise_pool_uuid)[0]
  }

  //
  questionsCount() {
    return this.attributes.draw_count
  }

  get drownExercisePoolsCountByUuid() {
    return [{
      exercise_pool_uuid: this.attributes.exercise_pool_uuid,
      draw_count: this.attributes.draw_count,
    }]
  }
  nodeType() { return "random_exercise" }

  defaultAttributes() {
    return {
      uuid: uuidv1(),
      draw_count: 1,
    }
  }

  /**
   * @return {Array}
   */
  validations() {
    return [
      {
        label: i18n.t("evalmee.home.teacher.quizEditor.randomExercise.errors.noPool"),
        condition: this.attributes.exercise_pool_uuid == null || !this.exercisePool,
      },
    ]
  }
}

class QuestionDraft extends DraftNode {


  get isMcq() { return this.attributes?.type === "mcq"}

  get isOpen() { return this.attributes?.type === "open"}

  get isSpreadsheet() { return this.attributes?.type === "spreadsheet"}

  get isText() { return this.attributes?.type === "text"}

  get editorBlockMinHeight() { return 110 }

  get group() {
    return {
      name: "questionChildren",
      put: ["questionChildren"],
      pull: true,
    }
  }

  get placeholder() { return this.behavior().placeholder }

  get commentable() { return this.behavior().commentable }

  get canHaveExpectedAnswer() { return this.behavior().canHaveExpectedAnswer }

  get indexable() { return this.behavior().indexable }

  get isAnswerable() { return this.behavior().isAnswerable }

  get allowMultipleChoices() { return this.behavior().allowMultipleChoices }

  get allowTags() { return this.behavior().allowTags }

  get positionInQuiz() {
    if (this.positionInQuizMemoized == null) {
      this.positionInQuizMemoized = this.behavior().positionInQuiz
    }
    return this.positionInQuizMemoized
  }

  /**
   * @return {String, undefined}
   * @param {Boolean} withCount
   */
  subtitleInstructions({ withCount }) {
    return this.behavior().subtitleInstructions({
      correctChoicesCount: this.correctChoicesCount(),
      withCount,
    })
  }


  behavior() { return new QuestionBehaviorFor(this).behavior }

  defaultChildren() { return this.behavior()?.defaultChildren() || [] }

  defaultAttributes() {
    return {
      type: "mcq",
      name: vueI18n.t("evalmee.home.teacher.quizEditor.question.all.defaultName"),
      uuid: uuidv1(),
      limited_content: false,
      limit_count: 1,
      limit_type: "words",
      coefficient: 1,
    }
  }

  nodeType() { return "question" }

  validChildrenTypes() { return ["choice"] }

  // isValid() {
  //   return super.isValid() &&
  //          this.validations().filter(v => v.condition).length === 0 &&
  //          this.children.every(child => child.isValid())
  // }

  hasEnoughCorrectChoices() { return this.behavior().hasEnoughCorrectChoices() }

  hasEnoughChoices() { return this.behavior().hasEnoughChoices() }

  correctChoicesCount() { return this.behavior().correctChoicesCount() }

  hasValidTitle() {
    return this.attributes.name != null &&
     this.attributes.name.replace(/^\s+|\s+$/g, "").length > 0
  }

  get choicesCount() {
    return this.children.length
  }

  hasTooManyChoices() {
    if (!this.isPaper) return false
    return this.choicesCount > 8
  }

  /**
   * @param {Number} position
   * @param {Number} questionsCount
   * @param {Boolean} paper
   *
   * @return {String}
   */
  subtitle(position, questionsCount, paper) {
    return this.behavior().subtitle(position, questionsCount, paper)
  }

  validations() {
    return [
      {
        label: vueI18n.t("evalmee.home.teacher.quizEditor.question.errors.doesNotHaveEnoughCorrectChoices"),
        condition: !this.hasEnoughCorrectChoices() && this.hasEnoughChoices(),
      },
      {
        label: vueI18n.t("evalmee.home.teacher.quizEditor.question.errors.hasTooManyChoices"),
        condition: this.hasTooManyChoices(),
      },
      {
        label: vueI18n.t("evalmee.home.teacher.quizEditor.question.errors.doesNotHaveEnoughChoices"),
        condition: !this.hasEnoughChoices(),
      },
      {
        label: vueI18n.t("evalmee.home.teacher.quizEditor.question.errors.unknownQuestionType"),
        condition: !this.isMcq && !this.isOpen && !this.isText && !this.isSpreadsheet,
      },
      {
        label: vueI18n.t("evalmee.home.teacher.quizEditor.question.errors.shouldHaveTitle"),
        condition: !this.hasValidTitle(),
      },
      {
        label: vueI18n.t("evalmee.home.teacher.quizEditor.question.errors.shouldHavePositiveCoefficient"),
        condition: !(Number.parseFloat(this.attributes.coefficient) >= 0),
      },
    ]
  }
}

class ChoiceDraft extends DraftNode {

  defaultAttributes() {
    return {
      name: "",
      correct: false,
      uuid: uuidv1(),
    }
  }

  get editorBlockContentMinHeight() { return 30 }

  nodeType() { return "choice" }

  hasValidChildren() {
    return true
  }

  validations() {
    return[
      {
        label: "Le choix doit avoir un nom",
        condition: this.attributes.name === "",
      },
    ]
  }

  isValid() {
    return this.validations().filter(v => v.condition).length === 0
  }
}


class ExercisesPoolDraft extends DraftNode {

  nodeType() { return "exercise_pool" }

  get editorBlockContentMinHeight() {
    return this.editorBlockMinHeight
  }

  defaultChildren() {
    return [
      new ExerciseDraft(),
      new ExerciseDraft(),
    ]
  }

  validChildrenTypes() { return ["exercise"] }

  /**
   * @return {Array}
   */
  validations() {
    return [
      {
        label: i18n.t("evalmee.home.teacher.quizEditor.exercisesPool.errors.shouldHaveSameQuestionCount"),
        condition: !this.allExercisesHaveSameQuestionsCount(),
      },
      {
        label: this.isAQuestionPool() ?
          i18n.t("evalmee.home.teacher.quizEditor.questionsPool.errors.shouldHaveSameCoefficientSum") :
          i18n.t("evalmee.home.teacher.quizEditor.exercisesPool.errors.shouldHaveSameCoefficientSum")
        ,
        condition: !this.allExercisesHaveSameCoefficient(),
      },
      {
        label: i18n.t("evalmee.home.teacher.quizEditor.exercisesPool.errors.empty"),
        condition: this.children.length === 0,
      },
    ]
  }

  /**
   * @return {Boolean}
   */
  allExercisesHaveSameQuestionsCount() {
    return this.children.every(exercise =>
      exercise.questionsCount() === this.children[0].questionsCount()
    )
  }

  /**
   * @return {Boolean}
   */
  allExercisesHaveSameCoefficient() {
    return this.children.every(exercise =>
      exercise.coefficientsSum() === this.children[0].coefficientsSum()
    )
  }

  /**
   * @return {Boolean}
   */
  isAQuestionPool() {
    return this.hasOnlyImplicitExercises()
  }

  /**
   * @return {Boolean}
   */
  hasOnlyImplicitExercises() {
    return this.children.every(exercise => exercise.isImplicit)
  }

}

/*
 * IndexIterator is used to set the position index of each node in the draft during the parsing
 * as position index is skipped for some nodes (e.g. Question with "text")
 */
class IndexIterator {
  constructor() {
    this.index = 0
  }

  next() {
    return this.index++
  }
}


const parseDraft = (draft, draftType = "online") => {
  const rootNode = draft?.content
  if (rootNode == null) return []

  return parseNodes([rootNode], draftType, new IndexIterator())
}

const parseExercicePoolDraft = (draft, draftType = "online") => {
  const nodes = draft?.exercise_pools_content
  if (nodes == null) return []
  return parseNodes(nodes, draftType)
}

const parseNodes = (nodes, draftType, indexIterator) => {
  return (nodes).map(node => {
    if(!node) return
    switch (node.type) {
    case "section" :
      return new SectionDraft(
        node.attributes,
        parseNodes(node.children, draftType, indexIterator),
        draftType
      )
    case "exercise" :
      return new ExerciseDraft(
        node.attributes,
        parseNodes(node.children, draftType, indexIterator),
        draftType
      )
    case "random_exercise" :
      return new RandomExerciseDraft(
        node.attributes,
        parseNodes(node.children, draftType, indexIterator),
        draftType
      )
    case "question" :
      return new QuestionDraft(
        node.attributes,
        parseNodes(node.children, draftType, indexIterator),
        draftType,
        indexIterator
      )
    case "choice" :
      return new ChoiceDraft(node.attributes, null, draftType)
    case "exercise_pool" :
      return new ExercisesPoolDraft(
        node.attributes,
        parseNodes(node.children, draftType, indexIterator),
        draftType
      )
    }

  })
}

const hasAllNodesValid = (draft, draftType) => {
  if (draft.content == null) return true
  return parseDraft(draft, draftType).every(node => node.hasValidChildren())
}


export default {
  parseDraft,
  parseExercicePoolDraft,
  hasAllNodesValid,
  SectionDraft,
  ExerciseDraft,
  RandomExerciseDraft,
  QuestionDraft,
  ChoiceDraft,
  DraftNode,
  ExercisesPoolDraft,
}
