import * as types from "../../mutation-types"
import axios from "axios"
import jsonApii from "../../../helpers/json_apii"
import VuexHelpers from "../../../helpers/vuex_helpers"

// initial state
const state = {
  tagsSets: [],
}

// getters
const getters = {
  tagsSet: state => schoolId => state.tagsSets.find(ts => ts.school_id === schoolId),
}

// actions
const actions = {
  fetchTagsSet({ commit }, school) {
    return axios
      .get(school.links.tags_set)
      .then(response => {
        commit(types.UPDATE_TAGS_SET, jsonApii.getData(response.data))
      })
  },

  updateTagsSet({ commit }, { school, tagsSet }) {
    return axios
      .patch(school.links.tags_set, tagsSet)
      .then(response => {
        commit(types.CREATE_OR_UPDATE_TAGS_SET, jsonApii.getData(response.data))
      })
  },
}

// mutations
const mutations = {
  [types.UPDATE_TAGS_SET](state, tagsSet) {
    VuexHelpers.createOrUpdate(state.tagsSets, tagsSet)
  },
  [types.CREATE_OR_UPDATE_TAGS_SET](state, tagsSet) {
    VuexHelpers.createOrUpdate(state.tagsSets, tagsSet)
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}
