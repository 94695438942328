import { Crisp } from "crisp-sdk-web"
import store from "@/store"

const CRISP_WEBSITE_ID = window.CRISP_WEBSITE_ID

const ChatSupportHelpers = {
  initCrisp() {
    Crisp.configure(CRISP_WEBSITE_ID, {
      autoload: false,
    })
    Crisp.message.onMessageReceived(() => {
      Crisp.chat.show()
      Crisp.chat.open()
    },
    )
    Crisp.chat.onChatClosed(() => {Crisp.chat.hide()})
  },


  updateCrispIndentification({ user, currentSchool, schools }) {
    if (!window.$crisp) this.initCrisp()

    try {
      Crisp.user.setCompany(currentSchool?.name, {})
      Crisp.user.setEmail(user.email, user.crisp_email_token)
      if (user.teacher) {
        Crisp.session.setSegments(["teacher"], true)
      }
      Crisp.session.setData({
        Teacher: user.teacher,
        School: currentSchool?.name,
        ...( user.last_app_used ? { LastAppUsed: user.last_app_used } : {}),
        Schools: schools.map((s) => s.name).join(", "),
        UserId: user.id,
      })
    } catch (error) {
      console.log("Error setting crisp session data", error)
    }

    try {
      if (Crisp.chat.unreadCount() === 0) {
        Crisp.chat.hide()
      }
    } catch (error) {
      console.log("Error hiding crisp chat", error)
    }

    window.LogRocket?.getSessionURL((sessionURL) => {
      Crisp.session.setData(
        { LogRocket: sessionURL },
      )
    })
  },

  showCrispChat() {
    Crisp.chat.show()
    Crisp.chat.open()
  },

  setCurrentQuizInfo({ quizName, quizId, quizzesAttemptId }) {
    Crisp.session.setData({
      LastQuizName: quizName,
      LastQuizId: quizId,
      LastScoreId: quizzesAttemptId,
    })
  },

  pushCrispEvent(eventName, data) {
    const dataStringified = Object.fromEntries(
      Object.entries(data).map(([key, value]) => [key, JSON.stringify(value)])
    )
    Crisp.session.pushEvent(eventName, dataStringified)
  },

  showCrispMessage(message) {
    if (Crisp.chat.isChatOpened()) return
    Crisp.message.showText(message)
  },

  prefillCrispMessage(message) {
    window.$crisp.push(["set", "message:text", [message]])
  },

  sendCrispMessage(message) {
    console.log("sendCrispMessage", message)
    Crisp.message.sendText(message)
  },

  hideCrispChat() {
    Crisp.chat.hide()
  },

  openHelpDeskArticle(slug) {
    const locale = store?.getters?.currentUser?.language || window.navigator.language
    Crisp.chat.openHelpdeskArticle(locale, slug)
  },

  updateReplayUrl(url) {
    if (!window.$crisp) this.initCrisp()

    Crisp.session.setData({ PostHogReplayUrl: url })
  },
}

export default ChatSupportHelpers
